html {
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'rajdhani', Roboto, sans-serif;
  background-color: #ffffff;
}

a {
  color: inherit;
  text-decoration: none
}

a:visited {
  color: inherit
}
